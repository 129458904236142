<header id="header"
        class="header">
    <div class="header-nav">
        <div class="nav">
            <a routerLink="{{homeRoute}}"
               class="main-menu-burger menu-top-upper-level">
                <img alt="PointGrab"
                     class="logo"
                     src="/assets/images/logo-pow.png" />
            </a>
            <menu-navigation *ngIf="isAuthenticated"></menu-navigation>
        </div>
    </div>
    <div class="header-menu">
        <div class="main-menu-header menu-bottom-upper-level">
        </div>
    </div>
</header>
