import {
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    MatDialogRef
} from '@angular/material/dialog';
import {
    NavigationStart,
    Router
} from '@angular/router';
import {
    AuthenticationProvider,
    GlobalRes
} from '@app/core';
import {
    SubscriptionNotifier
} from '@app/shared';

@Component({
    selector: 'logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
    host: {
        class: 'ngdialog ngdialog-overlay ngdialog-theme-default ngdialog-custom mat-dialog-custom'
    }
})
export class LogoutComponent implements OnInit, OnDestroy {

    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();

    constructor(
        private router: Router,
        private dialogRef: MatDialogRef<LogoutComponent>,
        private authenticationProvider: AuthenticationProvider,
        public res: GlobalRes
    ) {

    }

    public ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    public ngOnInit(): void {
        this.router.events.subscribe((e: any) => {
            if (e instanceof NavigationStart) {
                this.close();
            }
        });
    }

    public close(): void {
        this.dialogRef.close();
    }

    public logout(): void {
        this.authenticationProvider.logout();
        this.close();
    }


}
