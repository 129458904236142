
export class MenuHelper {

  public static mouseDownDocumentInit(): void {
    const menuHolders = document.querySelectorAll('.menu-holder');
    menuHolders.forEach(t => {
      t.addEventListener('click', (event: any) => {
        const input = t.querySelector('input');
        if (input) {
          const previosStateCheck = input.checked;
          document.querySelectorAll('.main-menu input').forEach((i: any) => {
            i.checked = false;
          });
          input.checked = !previosStateCheck;
        }
      });
    });
    MenuHelper.setStateMenuByRoute();
  }

  public static setStateMenuByRoute(): void {

    document.querySelectorAll('.sub-menu a').forEach((item: any) => {
      const routerlink = item.attributes.routerlink;
      if (routerlink) {
        const href = item.attributes.routerlink.value.toLowerCase();
        const currentLocation = location.href.toLowerCase();
        if ((new RegExp('/?' + href + '(/|$)')).test(currentLocation)) {
          item.classList.add('sub-menu-item-active');
        } else {
          item.classList.remove('sub-menu-item-active');
        }
      }
    });

    document.querySelectorAll('.menu-holder').forEach((item: any) => {
      const isActiveItem = item.querySelectorAll('.sub-menu-item-active').length > 0;
      const input = item.querySelector('input') || {};
      if (isActiveItem) {
        item.classList.add('menu-holder-active');
        input.checked = true;
      } else {
        item.classList.remove('menu-holder-active');
        input.checked = false;
      }
    });
  }

}
