<div class="breadcrumbs"
     [class.empty]="!breadCrumbs.items.length">
    <div class="item-breadcrumbs"
         *ngFor="let item of breadCrumbs.items">
        <div class="chevron-left-icon-black"></div>
        <span class="text-nowrap text-overflow item-action">
            <a [routerLink]="item.routerLink"
               [queryParams]="item.queryParams">{{item.displayName}} </a>
        </span>
    </div>
</div>