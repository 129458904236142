import {
    Injectable,
    Type
} from '@angular/core';
import { Observable } from 'rxjs';
import { ActivityPanelComponent } from '..';
import { ActivityPanelOptionsModel } from '../models/activityPanelOptions.model';
import {
    NavigationEnd,
    Router
} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ActivityPanelService {

    private activityPanel: ActivityPanelComponent;

    constructor(
        private router: Router
    ) {
        this.router
            .events
            .subscribe((e: any) => {
                if (e instanceof NavigationEnd) {
                    if (this.activityPanel?.options.isInLinePosition) {
                        this.close();
                    }
                }
            });
    }

    public initialize(panel: ActivityPanelComponent) {
        this.activityPanel = panel;
    }

    public destroy(): void {
        if (this.activityPanel) {
            this.activityPanel.beforeClose = null;
        }
    }

    public open<TComponent, TData>(
        innerComponent: Type<TComponent>,
        data: TData,
        options: ActivityPanelOptionsModel = new ActivityPanelOptionsModel()): Observable<any> {
        return this.activityPanel.openPanel(innerComponent, data, options);
    }

    public close(sender?: any): void {
        if (this.activityPanel) {
            this.activityPanel.closePanel(sender);
        }
    }

    public getActivityPanel(): ActivityPanelComponent {
        return this.activityPanel;
    }

}

