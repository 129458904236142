import { Injectable } from '@angular/core';
import { LayoutModel } from '../models/layout.model';
import {
    ActivatedRoute,
    Router
} from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root'
})

export class LayoutService {

    private model: LayoutModel;

    private pRouteState: any;
    private pRouteSnapshot: any;

    public get routeState(): any {
        return this.pRouteState;
    }

    public get routeSnapshot(): { queryParams: any, params: any, data: any } {
        return this.pRouteSnapshot;
    }

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private location: Location,
    ) {

    }

    public getLayoutModel() {
        if (!this.model) {
            this.model = new LayoutModel();
        }
        return this.model;
    }

    public extractRouteState(activatedRoute?: ActivatedRoute): void {
        const pActivatedRoute = activatedRoute || this.activatedRoute;
        const navigation = this.router.getCurrentNavigation();
        this.pRouteState = navigation?.extras.state;
        this.pRouteSnapshot = { queryParams: { ...pActivatedRoute.snapshot.queryParams }, params: { ...pActivatedRoute.snapshot.params }, data: { ...pActivatedRoute.snapshot.data } };
    }

    public clearRouteState(route: string): void {
        this.location.replaceState(route, '', null);
    }

}
