import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    MatDialogRef
} from '@angular/material/dialog';
import {
    NavigationStart,
    Router
} from '@angular/router';
import {
    GlobalRes,
    VersionModel
} from '@app/core';
import {
    ErrorHandlerService,
    FADE_IN_CONTENT_BY_OPACITY,
    PopupsDialogService,
    SubscriptionNotifier
} from '@app/shared';
import { DevicesStorageRemoteProvider } from '@app/storage';
import { environment } from '@environments/environment';

@Component({
    selector: 'version',
    templateUrl: './version.component.html',
    styleUrls: ['./version.component.scss'],
    providers: [
        VersionModel,
        DevicesStorageRemoteProvider],
    animations: [
        FADE_IN_CONTENT_BY_OPACITY],
    host: {
        class: 'ngdialog ngdialog-overlay ngdialog-theme-default ngdialog-custom mat-dialog-custom'
    }
})
export class VersionComponent implements OnInit, OnDestroy {

    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();

    public get frontEndVersion(): string {
        return environment.version;
    }

    constructor(
        private router: Router,
        private dialogRef: MatDialogRef<VersionComponent>,
        private popupsDialogService: PopupsDialogService,
        private elementRef: ElementRef,
        private errorHandlerService: ErrorHandlerService,
        public model: VersionModel,
        public res: GlobalRes
    ) {

    }

    public ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    public ngOnInit(): void {
        this.load();
        this.router
            .events
            .subscribe((e: any) => {
                if (e instanceof NavigationStart) {
                    this.close();
                }
            });
    }

    public copy(): void {
        navigator
            .clipboard
            .writeText(window.location.origin
                + '\nCP-CMS: ' + this.model.backendVersion
                + '\nCP-CMS-TELEMETRY: ' + this.model.telemetryVersion
                + '\nPLATFORM: ' + environment.version)
            ;
        this.close();
    }

    public close(): void {
        this.dialogRef.close();
    }

    private load(): void {
        this.popupsDialogService.startRelativeLoading(this.elementRef.nativeElement);
        this.model
            .load()
            .pipe(this.subscriptions.register())
            .subscribe({
                next: () => {
                    this.popupsDialogService.breackRelativeLoading(this.elementRef.nativeElement);
                },
                error: (error) => {
                    this.popupsDialogService.breackRelativeLoading(this.elementRef.nativeElement);
                    this.errorHandlerService.handleError(error);
                }
            });
    }

}
