import {
  Component,
  OnInit
} from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { LayoutModel } from '../../models';
import {
  UserProfileModel,
  AuthenticationProvider
} from 'src/app/core';
import { AnalyticsRoutesConstants } from '@app/modules/analytics/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  public currentUser: UserProfileModel;
  public layoutModel: LayoutModel;

  public get isAuthenticated(): boolean {
    return !!this.currentUser?.id;
  }

  public get homeRoute(): string {
    return AnalyticsRoutesConstants.ANALYTICS_LIVE_ROUTE;
  }

  constructor(
    private layoutService: LayoutService,
    private authenticationService: AuthenticationProvider
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  public ngOnInit() {
    this.layoutModel = this.layoutService.getLayoutModel();
    this.init();
  }

  private init() {
  }
}
