import { EventEmitter } from '@angular/core';
import { PerfectScrollbarComponent } from 'ngx-om-perfect-scrollbar';

export class LayoutModel {

    private pIsOpenMenu: boolean;
    public get isOpenMenu(): boolean {
        return this.pIsOpenMenu;
    }
    public set isOpenMenu(value: boolean) {
        this.pIsOpenMenu = value;
    }

    private pMainTitle: string;
    public get mainTitle(): string {
        return this.pMainTitle;
    }

    private pLayoutScroll: PerfectScrollbarComponent;
    public get layoutScroll(): PerfectScrollbarComponent {
        return this.pLayoutScroll;
    }

    private pOnLayoutScroll: EventEmitter<any>;
    public get onLayoutScroll(): EventEmitter<any> {
        return this.pOnLayoutScroll;
    }

    private pLayoutTheme: string;
    public get layoutTheme(): string {
        return this.pLayoutTheme;
    }

    constructor() {
        this.isOpenMenu = false;
        this.pOnLayoutScroll = new EventEmitter<any>();
    }

    public setMainTitle(title: string): void {
        this.pMainTitle = title;
    }

    public setTheme(theme: string): void {
        this.pLayoutTheme = theme;
    }

    public reset(): void {
        this.pMainTitle = '';
    }

    public initLayoutScroll(scrollComponentRef: PerfectScrollbarComponent): void {
        this.pLayoutScroll = scrollComponentRef;
    }

}
