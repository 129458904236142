<div class="ngdialog-content">
    <div class="popup">
        <div class="header">
            <div class="title">{{res.versions}}</div>
            <fa-icon [icon]="{prefix: 'fac',  iconName: 'cancel-light'}"
                     (click)="close()"
                     class="action-block fa-icon"></fa-icon>
        </div>
        <div class="popup-content form-block">
            <div class="flex-row">
                <div class="flex-columns">
                    <label class="label-text">{{res.cpCms}}</label>
                    <div class="value-block text-overflow"
                         title="{{model.backendVersion}}">
                        {{model.backendVersion}}
                    </div>
                </div>
            </div>
            <div class="flex-row">
                <div class="flex-columns">
                    <label class="label-text">{{res.cpCmsTelemetry}}</label>
                    <div class="value-block text-overflow"
                         title="{{model.telemetryVersion}}">
                        {{model.telemetryVersion}}
                    </div>
                </div>
            </div>
            <div class="flex-row">
                <div class="flex-columns">
                    <label class="label-text">{{res.platform}}</label>
                    <div class="value-block text-overflow"
                         title="{{frontEndVersion}}">
                        {{frontEndVersion}}
                    </div>
                </div>
            </div>
            <div class="panel-buttons">
                <div class="wrapper-ripple"
                     nk-ripple>
                    <input type="button"
                           value="{{res.copy}}"
                           (click)="copy()"
                           class="button primary-button" />
                </div>
            </div>
        </div>
    </div>
</div>