<div class="ngdialog-content"
    [formGroup]="model.form">
    <div class="popup"
        [class.message-sent]="isSent">
        <div class="header">
            <div class="title">{{res.sendYourFeedback}}</div>
            <fa-icon [icon]="{prefix: 'fac',  iconName: 'cancel-light'}"
                (click)="close()"
                class="action-block fa-icon"></fa-icon>
        </div>
        <div class="popup-content form-block"
            *ngIf="!isSent">
            <perfect-scrollbar class="scrollable-area">
                <div class="flex-row logo">
                    <div class="logo-image"></div>
                </div>
                <div class="flex-row email">
                    <div class="flex-columns">
                        <div class="value-block">
                            <input class="text-form"
                                formControlName="email"
                                autocomplete="email"
                                maxlength="100"
                                placeholder="{{res.email}}"
                                type="text">
                            <validation-messages formControlName="email"
                                [email]="res.validationMessages.emailIsWrong"
                                [required]="res.validationMessages.emailIsRequired">
                            </validation-messages>
                        </div>
                    </div>
                </div>
                <div class="flex-row rate">
                    <div class="flex-columns rate-title">
                        <label class="label-text">{{res.howWouldYouRateUs}}</label>
                    </div>
                    <div class="flex-columns rate-wrapper">
                        <mat-button-toggle-group name="rateToggleGroup"
                            formControlName="rate"
                            aria-label="Rate">
                            <mat-button-toggle [value]="feedbackRateEnum.good">
                                <div class="rate-content">
                                    <div class="icon rate-good"></div>
                                </div>
                            </mat-button-toggle>
                            <mat-button-toggle [value]="feedbackRateEnum.normally">
                                <div class="rate-content">
                                    <div class="icon rate-normally"></div>
                                </div>
                            </mat-button-toggle>
                            <mat-button-toggle [value]="feedbackRateEnum.badly">
                                <div class="rate-content">
                                    <div class="icon rate-badly"></div>
                                </div>
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
                <div class="flex-row message">
                    <div class="flex-columns">
                        <div class="value-block">
                            <textarea matInput
                                class="text-form"
                                formControlName="message"
                                placeholder="{{res.message}}"></textarea>
                            <validation-messages formControlName="message"
                                [required]="res.validationMessages.messageIsRequired">
                            </validation-messages>
                        </div>
                    </div>
                </div>
                <div class="panel-buttons">
                    <div class="wrapper-ripple"
                        nk-ripple>
                        <input type="button"
                            value="{{res.send}}"
                            [disabled]="isDisabledSend()"
                            (click)="send()"
                            class="button primary-button" />
                    </div>
                </div>
            </perfect-scrollbar>

        </div>
        <div class="popup-content"
            @fadeInContentByOpacity
            *ngIf="isSent">
            <div class="message-done-image"></div>
            <div class="message-done-title">{{res.thanksForYourFeedback}}</div>
        </div>

    </div>
</div>