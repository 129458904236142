import {
  Component,
  Input,
  OnInit,
  ViewChild,
  Type,
  NgModuleRef,
  Injector,
  HostBinding
} from '@angular/core';

import { TemplateHostDirective } from '../directives/templateHost.directive';
import { FADE_IN_CONTENT_BY_OPACITY_FOR_HIDDEN } from '@appConstants';
import { ACTIVITY_PANEL_DATA } from '../tokens';

@Component({
  selector: 'activity-content-host',
  template: `<div (@fadeInContentByOpacityForHidden.start)="animationStart()"
   (@fadeInContentByOpacityForHidden.done)="animationDone()"
   [@fadeInContentByOpacityForHidden]="componentLoading ? 'hidden':'visible'"> <ng-template template-host></ng-template></div>`,
  animations: [
    FADE_IN_CONTENT_BY_OPACITY_FOR_HIDDEN],
})

export class ActivityContentHostComponent implements OnInit {

  @HostBinding('class.ng-animating') componentLoading: boolean;

  public isAnimating: boolean;

  @ViewChild(TemplateHostDirective, { static: true }) templateHost: TemplateHostDirective;
  pInnerComponent: Type<any>;
  get innerComponent(): Type<any> {
    return this.pInnerComponent;
  }
  @Input('innerComponent')
  set innerComponent(value: Type<any>) {
    this.pInnerComponent = value;
    this.componentLoading = true;
  }

  @Input() data: any;
  @Input() moduleRef: NgModuleRef<any>;

  constructor(
    private injector: Injector) {
    this.componentLoading = false;
  }

  ngOnInit() {
    this.loadComponent();
  }

  public animationDone(): void {
    this.isAnimating = false;
    if (this.componentLoading) {
      this.loadComponent();
    }
  }

  public animationStart(): void {
    this.isAnimating = true;
  }

  private loadComponent(): void {
    const viewContainerRef = this.templateHost.viewContainerRef;
    viewContainerRef.clear();
    const activityPanelInjector = Injector.create(
      {
        providers: [
          { provide: ACTIVITY_PANEL_DATA, useValue: this.data }
        ],
        parent: this.injector
      }
    );
    viewContainerRef.createComponent(this.innerComponent, { injector: activityPanelInjector, ngModuleRef: this.moduleRef });
    this.componentLoading = false;
  }
}
