import {
    Component,
    OnInit,
    ViewEncapsulation,
    Type,
    NgModuleRef,
    EventEmitter
} from '@angular/core';
import { Observable } from 'rxjs';

import {
    ActivityPanelService
} from '../services/activityPanel.service';
import {
    FADE_IN_CONTENT_RIGHT_SLIDER,
    FADE_IN_CONTENT_WIDTH_SLIDER
} from '@appConstants';
import { first } from 'rxjs/operators';
import { ActivityPanelOptionsModel } from '../models';
import {
    AnimationTriggerMetadata,
    trigger,
    transition,
    style,
    animate
} from '@angular/animations';

const FADE_BACKDROP: AnimationTriggerMetadata = trigger('fadeBackdrop', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 0.3 }))
    ]),
    transition(':leave', [
        style({ opacity: 0.3 }),
        animate('300ms', style({ opacity: 0 })),
    ])
]);

@Component({
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./activityPanel.component.scss'],
    animations: [
        FADE_IN_CONTENT_RIGHT_SLIDER,
        FADE_IN_CONTENT_WIDTH_SLIDER,
        FADE_BACKDROP],
    selector: 'activity-panel,ng2-activity-panel',
    template: `
              <div *ngIf="open" [@fadeBackdrop] class="activity-panel-backdrop" (click)="backDropClick()" (touchstart)="backDropClick()"></div>
              <div *ngIf="open && !options.isInLinePosition" [@fadeInContentRightSlider] (@fadeInContentRightSlider.done)="onAnimationDone()" [ngClass]='ngClass'  class="activity-panel menu-top-level">
              <activity-content-host [innerComponent]="innerComponent" [data]="data" [moduleRef]="moduleRef"></activity-content-host>
              </div>
              <div *ngIf="open && options.isInLinePosition" [@fadeInContentWidthSlider] (@fadeInContentWidthSlider.done)="onAnimationDone()" [ngClass]='ngClass'  class="activity-panel menu-top-level">
              <activity-content-host [innerComponent]="innerComponent" [data]="data" [moduleRef]="moduleRef"></activity-content-host>
              </div>
            `,
    host: {
        '[class.content-top-level]': 'options.isInLinePosition'
    }
})

export class ActivityPanelComponent implements OnInit {

    private observerPanel: any;
    private isBackDrop: boolean;

    private pBeforeClose: Observable<boolean>;
    public get beforeClose(): Observable<boolean> {
        return this.pBeforeClose;
    }
    public set beforeClose(value: Observable<boolean>) {
        this.pBeforeClose = value;
    }

    private pAfterClose: Observable<void>;
    public get afterClose(): Observable<void> {
        return this.pAfterClose;
    }
    public set afterClose(value: Observable<void>) {
        this.pAfterClose = value;
    }

    private pOptions: ActivityPanelOptionsModel;
    public get options(): ActivityPanelOptionsModel {
        return this.pOptions;
    }

    public innerComponent: Type<any>;
    public data: any;
    public ngClass: string;
    public moduleRef: NgModuleRef<any>;

    private pOpen = false;
    public get open(): boolean {
        return this.pOpen;
    }

    public onOpen: EventEmitter<any>;

    constructor(
        private activityPanelService: ActivityPanelService) {
        this.onOpen = new EventEmitter<any>();
        this.pOptions = new ActivityPanelOptionsModel();
    }

    public ngOnInit() {
        this.activityPanelService.initialize(this);
    }

    public async closePanel(sender?: any) {
        if (this.beforeClose && sender !== 'navigationStart') {
            if (!await this.beforeClose.pipe(first()).toPromise()) {
                return;
            }
        }

        if (this.pOpen) {
            this.pOpen = false;
            if (this.observerPanel) {
                this.observerPanel.next(sender);
                this.observerPanel.complete();
            }
        }
    }

    public openPanel<TComponent, TData>(
        innerComponent: Type<TComponent>,
        data: TData,
        options: ActivityPanelOptionsModel): Observable<any> {
        this.ngClass = options.ngClass;
        this.pOptions = options;

        this.pOpen = true;
        this.innerComponent = innerComponent;
        this.data = data;
        this.moduleRef = options.moduleRef;
        this.isBackDrop = options.isBackDrop;

        const observable = new Observable((observer: any) => {
            this.observerPanel = observer;
        });
        return observable;
    }

    public onAnimationDone(): void {
        if (this.pOpen) {
            this.onOpen.emit();
        } else {
            if (this.afterClose) {
                this.afterClose.pipe(first()).toPromise();
            }
        }
    }

    public backDropClick(): any {
        if (this.isBackDrop) {
            this.closePanel();
        }
    }

}
