import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-404',
  template: `
    <h1 style="text-align: center">404 | Not Found!</h1>
  `
})

export class Page404Component implements OnInit {
  ngOnInit(): void {
   }
}
