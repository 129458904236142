import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef
} from '@angular/core';
import { ROUTER_TRANSITION } from '@appConstants';
import { LayoutService } from '../../services/layout.service';
import { LayoutModel } from '../../models';
import { PerfectScrollbarComponent } from 'ngx-om-perfect-scrollbar';
import { ActivityPanelService } from '@app/layout';
import { ChildrenOutletContexts } from '@angular/router';

@Component({
  selector: 'app-body',
  animations: [ROUTER_TRANSITION],
  styleUrls: ['./body.component.scss'],
  templateUrl: './body.component.html',
})
export class BodyComponent implements OnInit, AfterViewInit {

  public layoutModel: LayoutModel;
  @ViewChild(PerfectScrollbarComponent, { static: false }) scrollComponentRef?: PerfectScrollbarComponent;

  public get isInLinePositionForActivityPanel(): boolean {
    return !!this.activityPanelService.getActivityPanel()?.options.isInLinePosition;
  }

  constructor(
    private childrenOutletContexts: ChildrenOutletContexts,
    private changeDetectorRef: ChangeDetectorRef,
    private layoutService: LayoutService,
    private activityPanelService: ActivityPanelService,
  ) {

  }

  public ngOnInit() {
    this.layoutModel = this.layoutService.getLayoutModel();

  }

  public ngAfterViewInit(): void {
    this.layoutModel.initLayoutScroll(this.scrollComponentRef);
    this.changeDetectorRef.detectChanges();
  }

  public getRouteAnimationData(): string {
    return this.childrenOutletContexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

  public onScrollEvent(event: any): void {
    this.layoutModel.onLayoutScroll.emit(event);
  }
}
