<perfect-scrollbar [config]="{suppressScrollX: false}"
    (psScrollY)="onScrollEvent($event)"
    (psScrollX)="onScrollEvent($event)">
    <div id="wrap"
        [ngClass]="layoutModel.layoutTheme"
        [class.activity-panel-in-line]="isInLinePositionForActivityPanel">
        <div id="main"
            class="body-content content-top-level">
            <bread-crumbs></bread-crumbs>
            <div class="main-state-transition"
                [@routerTransition]="getRouteAnimationData()">
                <router-outlet> </router-outlet>
            </div>
        </div>
        <ng2-activity-panel #activityPanel></ng2-activity-panel>
    </div>
</perfect-scrollbar>