import {
    Component,
    ElementRef,
    OnInit
} from '@angular/core';
import {
    MatDialogRef
} from '@angular/material/dialog';
import {
    NavigationStart,
    Router
} from '@angular/router';
import {
    FeedbackModel,
    FeedbackRateEnum,
    GlobalRes
} from '@app/core';
import {
    ErrorHandlerService,
    FADE_IN_CONTENT_BY_OPACITY,
    PopupsDialogService
} from '@app/shared';
import { AnalyticsStorageRemoteProvider } from '@app/storage';

@Component({
    selector: 'feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss'],
    providers: [
        FeedbackModel,
        AnalyticsStorageRemoteProvider],
    animations: [
        FADE_IN_CONTENT_BY_OPACITY],
    host: {
        class: 'ngdialog ngdialog-overlay ngdialog-theme-default ngdialog-custom mat-dialog-custom'
    }
})
export class FeedbackComponent implements OnInit {

    public isSent: boolean;

    public feedbackRateEnum = FeedbackRateEnum;

    constructor(
        private router: Router,
        private dialogRef: MatDialogRef<FeedbackComponent>,
        private popupsDialogService: PopupsDialogService,
        private elementRef: ElementRef,
        private errorHandlerService: ErrorHandlerService,
        public model: FeedbackModel,
        public res: GlobalRes
    ) {
    }

    public ngOnInit(): void {
        this.router.events.subscribe((e: any) => {
            if (e instanceof NavigationStart) {
                this.close();
            }
        });
    }

    public close(): void {
        this.dialogRef.close();
    }

    public send(): void {
        this.popupsDialogService.startRelativeLoading(this.elementRef.nativeElement);
        this.model
            .send()
            .then(() => {
                this.isSent = true;
                setTimeout(() => {
                    this.close();
                }, 3000);
                this.popupsDialogService.breackRelativeLoading(this.elementRef.nativeElement);
            })
            .catch((error) => {
                this.popupsDialogService.breackRelativeLoading(this.elementRef.nativeElement);
                this.errorHandlerService.handleError(error);
            });
    }

    public isDisabledSend(): boolean {
        return !(this.model.form.valid && this.model.form.dirty);
    }

}
