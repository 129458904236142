import {
  Component,
  OnInit
} from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { LayoutModel } from '../../models';
import { MenuHelper } from '../../helpers/menu.helper';
import {
  Router,
  NavigationEnd
} from '@angular/router';
import {
  UserProfileModel,
  AuthenticationProvider,
  GlobalRes,
  RolesEnum,
  PermissionsProvider
} from '@app/core';
import { MatDialog } from '@angular/material/dialog';
import { LogoutComponent } from '../logout';
import { CpmsRoutesConstants } from '@app/modules/cpms/constants';
import { AnalyticsRoutesConstants } from '@app/modules/analytics/constants';
import { FeedbackComponent } from '../feedback';
import { VersionComponent } from '../version';
import { environment } from '@environments/environment';
import { IntegrationsRoutesConstants } from '@app/modules/integrations/constants';

@Component({
  selector: 'menu-navigation',
  templateUrl: './menuNavigation.component.html',
  styleUrls: ['./menuNavigation.component.scss'],
})
export class MenuNavigationComponent implements OnInit {

  private currentUser: UserProfileModel;

  public layoutModel: LayoutModel;

  public get userName(): string {
    return this.currentUser.firstName + ' ' + this.currentUser.lastName;
  }

  public get cpmsFloorsRoute(): string {
    return CpmsRoutesConstants.CPMS_FLOORS_ROUTE;
  }

  public get cpmsManagementRoute(): string {
    return CpmsRoutesConstants.CPMS_MANAGMENT_ROUTE;
  }

  public get supportRoute(): string {
    return CpmsRoutesConstants.SUPPORT_ROUTE;
  }

  public get apiReferencePath(): Array<string> {
    return [environment.apiUrl + 'be/swagger-ui/index.html'];
  }

  public get analyticsRoute(): string {
    return AnalyticsRoutesConstants.ANALYTICS_ROUTE;
  }

  public get integrationsRoute(): string {
    return IntegrationsRoutesConstants.INTEGRATIONS_ROUTE;
  }

  public get versionVisible(): boolean {
    return this.currentUser.role === RolesEnum.pgAdmin || this.currentUser.role === RolesEnum.pgDemo;
  }

  public get managementAccess(): boolean {
    return this.permissionsProvider.cpmsManagement;
  }

  public get integrationsAccess(): boolean {
    return this.permissionsProvider.integrations;
  }

  constructor(
    private layoutService: LayoutService,
    private router: Router,
    private dialog: MatDialog,
    private authenticationService: AuthenticationProvider,
    private permissionsProvider: PermissionsProvider,
    public res: GlobalRes
  ) {
    this.layoutModel = this.layoutService.getLayoutModel();
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  public ngOnInit() {
    this.init();
  }

  public expandMenu(): void {
    const hamburger = document.querySelector('#hamburger') as any;
    hamburger.checked = true;
    this.layoutModel.isOpenMenu = true;
  }

  public oldVersion(): void {
    if (window.location.origin.indexOf('localhost') > -1) {
      window.location.href = 'https://oldcpms.dev.pointgrab.com';
    }
    else {
      window.location.href = window.location.origin.replace('platform', 'oldcpms');
    }
  }

  public logoutConfirm(): void {
    this.dialog.open(LogoutComponent, {
    });
  }

  public version(): void {
    this.dialog.open(VersionComponent, {
    });
  }

  public sendFeedback(): void {
    this.dialog.open(FeedbackComponent, {
    });
  }

  private init() {
    MenuHelper.mouseDownDocumentInit();
    this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.layoutModel.isOpenMenu = false;
        const hamburger = document.querySelector('#hamburger') as any;
        if (hamburger) {
          hamburger.checked = false;
        }
        setTimeout(() => {
          MenuHelper.setStateMenuByRoute();
        });
      }
    });
  }
}
