import { Component } from '@angular/core';
import { LayoutService } from '@app/layout/services';

@Component({
  selector: 'main-layout',
  templateUrl: './mainLayout.component.html'
})
export class MainLayoutComponent {

  constructor(
    private layoutService: LayoutService,
  ) {
    this.layoutService.extractRouteState();
  }
}
