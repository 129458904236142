import { BreadCrumbsItemModel } from '.';

export class BreadCrumbsModel {

    private pItems: Array<BreadCrumbsItemModel>;
    public get items(): Array<BreadCrumbsItemModel> {
        return this.pItems;
    }

    constructor() {
        this.pItems = new Array<BreadCrumbsItemModel>();
    }

    public clear(): void {
        this.items.length = 0;
    }

    public add(item: BreadCrumbsItemModel): void {
        this.items.push(item);
    }
}
