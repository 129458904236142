import {
    NgModule
} from '@angular/core';
import {
    RouterModule
} from '@angular/router';
import {
    BreadCrumbsComponent,
    HeaderComponent,
    BodyComponent,
    Page404Component,
    FeedbackComponent,
    LogoutComponent,
    MainLayoutComponent,
    MenuNavigationComponent,
    VersionComponent
} from './components';
import { CommonModule } from '@angular/common';
import {
    TemplateHostDirective,
    ActivityPanelComponent,
    ActivityContentHostComponent
} from './activityPanel';
import { PerfectScrollbarModule } from 'ngx-om-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-om-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-om-perfect-scrollbar';
import { TranslateModule } from '@ngx-translate/core';
import { IconsModule } from '@app/shared/controls/icons';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

const COMPONENTS = [
    BreadCrumbsComponent,
    HeaderComponent,
    BodyComponent,
    MainLayoutComponent,
    TemplateHostDirective,
    ActivityPanelComponent,
    ActivityContentHostComponent,
    MenuNavigationComponent,
    Page404Component,
    FeedbackComponent,
    LogoutComponent,
    VersionComponent
];

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        IconsModule,
        PerfectScrollbarModule,
        MatButtonToggleModule
    ],
    exports: [COMPONENTS],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ],
    declarations: [COMPONENTS]
})
export class LayoutModule {

}
