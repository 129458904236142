<div class="main-menu-wrapper menu-top-bottom-level">
    <div class="main-menu">
        <ul class="top-menu">
            <li class="menu-holder">
                <a class="menu-item"
                    routerLink="{{cpmsFloorsRoute}}"
                    routerLinkActive="active">
                    <fa-icon [icon]="{prefix: 'fac',  iconName: 'menu-floor'}"
                        class="fa-icon menu-floor"></fa-icon>
                    <span class="label">{{res.floors}}</span>
                </a>
            </li>
            <li class="menu-holder"
                *ngIf="managementAccess">
                <a class="menu-item"
                    routerLink="{{cpmsManagementRoute}}"
                    routerLinkActive="active">
                    <fa-icon [icon]="{prefix: 'fac',  iconName: 'management'}"
                        class="fa-icon management"></fa-icon>
                    <span class="label">{{res.management}}</span>
                </a>
            </li>
            <li class="menu-holder">
                <a class="menu-item"
                    routerLink="{{analyticsRoute}}"
                    routerLinkActive="active">
                    <fa-icon [icon]="{prefix: 'fac',  iconName: 'analytics-menu'}"
                        class="fa-icon analytics-menu"></fa-icon>
                    <span class="label">{{res.analytics}}</span>
                </a>
            </li>
            <li class="menu-holder"
                *ngIf="integrationsAccess">
                <a class="menu-item"
                    routerLink="{{integrationsRoute}}"
                    routerLinkActive="active">
                    <fa-icon [icon]="{prefix: 'fac',  iconName: 'puzzles'}"
                        class="fa-icon analytics-menu"></fa-icon>
                    <span class="label">{{res.integrations}}</span>
                </a>
            </li>
        </ul>
        <div class="menu-bottom">
            <nav class="dropdown"
                role="navigation">
                <ul>
                    <li class="menu-holder">
                        <a class="menu-item"
                            href="{{supportRoute}}">
                            <fa-icon [icon]="{prefix: 'fac',  iconName: 'support'}"
                                class="fa-icon support"></fa-icon>
                            <span class="label">{{res.support}}</span>
                        </a>
                    </li>
                    <li class="line">
                    </li>
                    <li class="profile-item">
                        <a aria-haspopup="true"
                            class="profile-item-action">
                            <img class="profile-item-action-image"
                                src="assets/images/profile.svg" />
                        </a>
                        <ul aria-label="submenu"
                            class="dropdown-popup app-border">
                            <li class="user-name text-overflow"
                                title="{{userName}}">{{ userName }}</li>
                            <li><a href="{{apiReferencePath}}"
                                    target="_blank">{{ res.apiReference }}</a></li>
                            <li *ngIf="versionVisible"
                                (click)="version()">{{ res.version }}</li>
                            <li (click)="logoutConfirm()">
                                <a>{{res.logout}}</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
<div class="right-menu-wrapper menu-top-bottom-level">
    <div class="right-menu">
        <div class="wrapper-ripple app-border"
            nk-ripple>
            <div class="right-menu-item feedback action-block"
                (click)="sendFeedback()">
                <div class="title">{{res.feedback}}</div>
                <div class="title-icon">
                    <fa-icon [icon]="{prefix: 'fac',  iconName: 'feedback'}"
                        class="fa-icon feedback"></fa-icon>
                </div>
            </div>
        </div>
    </div>
</div>