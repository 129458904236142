<div class="ngdialog-content">
    <div class="popup">
        <div class="header">
            <fa-icon [icon]="{prefix: 'fac',  iconName: 'cancel-light'}"
                     (click)="close()"
                     class="action-block fa-icon"></fa-icon>
        </div>

        <div class="popup-content">
            <h2>{{ 'MODAL.logoutTitle' | translate }}</h2>
            <h3>{{ 'MODAL.logoutConfirm' | translate }}</h3>

            <button (click)="logout()"
                    class="btn btn-danger">{{ 'MODAL.logoutButtonText' | translate }}</button>
        </div>

    </div>
</div>